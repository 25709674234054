/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';

// Utilities
@import 'node_modules/bootstrap/scss/utilities/api';

@import 'custom/index';

.errorMassage{
    color:red !important;
}